/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import api from '../../../services/api';
import StepLayout from '../../../pages/_layouts/step';
import { FaArrowLeft, FaEye } from 'react-icons/fa';


const Step5 = props => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [cpfsocio1, setCpfsocio1] = useState(false);
  const [cpfsocio2, setCpfsocio2] = useState(false);
  const [cpfsocio3, setCpfsocio3] = useState(false);
  const { userData, currentStep, jumpToStep } = props;

  useEffect(() => {
    handleDataVerify();
  })

  const handleDataVerify = () => {
    if (userData.cnpjcpfsocio1) {
      setCpfsocio1(true);
    }

    if (userData.cnpjcpfsocio2) {
      setCpfsocio2(true);
    }
    if (userData.cnpjcpfsocio3) {
      setCpfsocio3(true);
    }
  }

  const validCpf = cpf => {
      cpf = cpf.replace(/[^\d]+/g, '');
      if (cpf === '') return false;
      // Elimina CPFs invalidos conhecidos
      if (cpf.length !== 11 || cpf.split('').every(char => char === cpf[0]))
        return false;
      // Valida 1o digito
      let add = 0;
      for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
      let rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      if (rev !== parseInt(cpf.charAt(9))) return false;
      // Valida 2o digito
      add = 0;
      for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) rev = 0;
      if (rev !== parseInt(cpf.charAt(10))) return false;
      return true;
  };

  const schema = Yup.object().shape({
    nomesocio1: Yup.string().required(
      'Ops, você precisa informar pelo menos um sócio'
    ),
    cnpjcpfsocio1: Yup.string().when('nomesocio1', {
      is: '',
      then: Yup.string(),
      otherwise: Yup.string()
        .required('É necessário informar um CPF')
        .test(
          'test-name',
          'Desculpe, o CPF informado não é válido!',
          value => {
            if(cpfsocio1 === true){
              return true;
            }
            return validCpf(value);
          }
        ),
    }),

    nomesocio2: Yup.string(),
    cnpjcpfsocio2: Yup.string().when('nomesocio2', {
      is: '',
      then: Yup.string(),
      otherwise: Yup.string()
        .required('É necessário informar um CPF')
        .test(
          'test-name',
          'Desculpe, o CPF informado não é válido!',
          value => {
            if(cpfsocio2 === true){
              return true;
            }
            return validCpf(value);
          }
        ),
      // .matches(regexCpfCnpj, 'CPF inválido!'),
    }),

    nomesocio3: Yup.string(),
    cnpjcpfsocio3: Yup.string().when('nomesocio3', {
      is: '',
      then: Yup.string(),
      otherwise: Yup.string()
        .required('É necessário informar um CPF')
        .test(
          'test-name',
          'Desculpe, o CPF informado não é válido!',
          value => {
            if(cpfsocio3 === true){
              return true;
            }
            return validCpf(value);
          }
        ),
      // .matches(regexCpfCnpj, 'CPF inválido!'),
    }),
  });

  const handleSubmit = async formData => {
    try {
      setLoading(true);
      
      const response = await api.post('auth/cadastro', {
        ...userData,
        ...formData,
      });
     
      const { error, mensagem } = response.data;

      if (error === 1) {
        // eslint-disable-next-line no-throw-literal
        throw { error: mensagem };
      } else {
        setLoading(false);
        // Jump to the next step if everything is okay
        jumpToStep(currentStep + 1);
      }
    } catch (err) {
      // console.log(err.error);
      const errMessage =
        err.error ||
        'Ops! Ocorreu um erro ao finalizar seu cadastro. Por favor, verifique seus dados e tente novamente.';

      setMessage(errMessage);
      setLoading(false);
    }
  };

  const onGoBackClick = () => {
    jumpToStep(currentStep - 1);
  };

  return (
    <StepLayout>
      <div>
        <span className="wizard-header"> 
          <button
            type="button"
            className="button-back"
            onClick={onGoBackClick}
          >
            <FaArrowLeft/>
            Voltar
          </button>
        </span>   
        <div className="step step3">
          <div className="row">
            <h2>Sócios</h2>
            <div className="subtitle">
              <p>
                Quase lá! Verifique agora os dados dos sócios e complete o que
                estiver faltando.
              </p>
            </div>
            <Form
              className="form signup-form"
              schema={schema}
              onSubmit={handleSubmit}
              initialData={{
                nomesocio1: userData.nomesocio1,
                // cnpjcpfsocio1: userData.cnpjcpfsocio1,
                nomesocio2: userData.nomesocio2,
                // cnpjcpfsocio2: userData.cnpjcpfsocio2,
                nomesocio3: userData.nomesocio3,
                // cnpjcpfsocio3: userData.cnpjcpfsocio3,
              }}
            >
              <div className="form-item">
                <label>Sócio 1 / Sócio Proprietário</label>
                <Input
                  placeholder="Insira o nome completo"
                  name="nomesocio1"
                  disabled={loading}
                  value={userData.nomesocio1}
                  readOnly={userData.nomesocio1}
                  inputMode="text"
                />
              </div>
              <div className="form-item">
                <label>CPF</label>
                <Input
                  name="cnpjcpfsocio1"
                  disabled={loading}
                  value={userData.cnpjcpfsocio1}
                  readOnly={cpfsocio1}
                  maxLength="18"
                  inputMode="decimal"
                />
              </div>
              <div className="form-item">
                <label>Sócio 2</label>
                <Input
                  placeholder="Insira o nome completo"
                  name="nomesocio2"
                  disabled={loading}
                  value={userData.nomesocio2}
                  readOnly={userData.nomesocio2}
                  inputMode="text"
                />
              </div>
              <div className="form-item">
                <label>CPF</label>
                <Input
                  name="cnpjcpfsocio2"
                  disabled={loading}
                  value={userData.cnpjcpfsocio2}
                  readOnly={cpfsocio2}
                  maxLength="18"
                  inputMode="decimal"
                />
              </div>
              <div className="form-item">
                <label>Sócio 3</label>
                <Input
                  placeholder="Insira o nome completo"
                  name="nomesocio3"
                  disabled={loading}
                  value={userData.nomesocio3}
                  readOnly={userData.nomesocio3}
                  inputMode="text"
                />
              </div>
              <div className="form-item">
                <label>CPF</label>
                <Input
                  name="cnpjcpfsocio3"
                  disabled={loading}
                  value={userData.cnpjcpfsocio3}
                  readOnly={cpfsocio3}
                  maxLength="18"
                  inputMode="decimal"
                />
              </div>

              <div>
                <span>{message}</span>
              </div>

              <div className="wizard-footer ">
                <button type="submit" disabled={loading}>
                  {loading ? 'Carregando...' : 'Finalizar'}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </StepLayout>
  );
};

Step5.propTypes = {
  userData: PropTypes.isRequired,
  jumpToStep: PropTypes.func,
  currentStep: PropTypes.number.isRequired,
};

Step5.defaultProps = {
  jumpToStep: () => { },
};

export default Step5;
