import React from 'react';
import PropTypes from 'prop-types';
import { useAppContext } from '../../../context/AppContext';
import { Container, Content, Root } from './styles';
import logo from '../../../assets/nologo.svg'
import fundoPrincipal from '../../../assets/fundo-principal.png';
import Header from '../../../components/Header';

export default function AuthLayout({ children }) {
  const {siteConfig} = useAppContext()
  const fundoPrincipal = siteConfig?.app_loginscreen_background;
  return (
    <>
      <Container>
        <Header />
      </Container>
      <Content>
        {children}
        {fundoPrincipal && (
          <div className="fundo-principal">
            <div
              style={{
                backgroundImage: `url(${fundoPrincipal})`,
                height: '100%',
                width: '100%',
                opacity: '0.2',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: 'brightness(0.6)',
              }}
            />
            <img
              className="fundo-logo"
              src={siteConfig?.app_logo || logo}
            alt={siteConfig?.app_name}
            />
          </div>
        )}
      </Content>
    </>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
