/* eslint-disable no-console */
import React, { useState } from 'react';

import StepZilla from 'react-stepzilla';
import AuthLayout from '../../pages/_layouts/auth';
import Header from '../../components/Header';

import './styles.scss';

import Step1 from './Steps/Step1';
import Step2 from './Steps/Step2';
import Step3 from './Steps/Step3';
import Step4 from './Steps/Step4';
import Step5 from './Steps/Step5';
import Step6 from './Steps/Step6';

export default function SignUp() {
  const [step, setStep] = useState(0);
  const [userData, setUserData] = useState({});

  const onStepChange = stepChange => {
    setStep(stepChange);
  };

  const onSetUserData = store => {
    setUserData(prevState => ({
      ...prevState,
      ...store,
    }));
  };

  const Layout = AuthLayout;

  const wizardSteps = [
    {
      name: 'Início',
      component: (
        <Step1
          currentStep={step}
          userData={userData}
          onSetUserData={onSetUserData}
        />
      ),
    },
    {
      name: 'Origem Contato',
      component: (
        <Step2
          userData={userData}
          currentStep={step}
          onSetUserData={onSetUserData}
        />
      ),
    },
    {
      name: 'Contato',
      component: (
        <Step3
          userData={userData}
          currentStep={step}
          onSetUserData={onSetUserData}
        />
      ),
    },
    {
      name: 'Empresa',
      component: (
        <Step4
          userData={userData}
          currentStep={step}
          onSetUserData={onSetUserData}
        />
      ),
    },
    {
      name: 'Sócios',
      component: (
        <Step5
          userData={userData}
          currentStep={step}
          onSetUserData={onSetUserData}
        />
      ),
    },
    {
      name: 'Fim',
      component: <Step6 currentStep={step} />,
    },
  ];

  return (
    <>
        {step > 0 && <Header />}
        {step > 0 && step < wizardSteps.length - 1 &&(
          <div className='container-showing-steps'>  
            <span className="showing-steps">Passo {step} de {wizardSteps.length - 2}</span>
          </div>
        )}
        <div className="step-progress">
          <StepZilla
            steps={wizardSteps}
            nextButtonText="Prosseguir"
            backButtonText="Anterior"
            onStepChange={onStepChange}
            showNavigation={false}
            stepsNavigation={false}
            preventEnterSubmission
            showSteps={step > 0 && step < wizardSteps.length - 1}
          />
        </div>
    </>
  );
}
