import React, { useState, useEffect, createContext, useContext } from 'react';
import { BrowserRouter, Router } from 'react-router-dom';

import Routes from './routes';
import history from './services/history';
import { AppProvider, useAppContext } from './context/AppContext';
import GlobalStyle from './styles/global';

/* // Criando um contexto para o estado global
const AppContext = createContext();

function AppProvider({ children }) {
  const storedSiteConfig = localStorage.getItem('siteConfig');
  const initialSiteConfig = storedSiteConfig ? JSON.parse(storedSiteConfig) : {};

  const [siteConfig, setSiteConfig] = useState(initialSiteConfig);
  const [isLoaded, setIsLoaded] = useState(!!storedSiteConfig); // Define isLoaded como true se houver dados no localStorage

  const fetchData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/v1/site-config`;

      const response = await fetch(apiUrl);
      const data = await response.json();
      console.log(data);
      console.log(data.app_loginscreen_background);

      if (data) {
        setSiteConfig(data);
        localStorage.setItem('siteConfig', JSON.stringify(data));
        setIsLoaded(true);
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isLoaded) {
      fetchData();
    }
  }, [isLoaded]);

  return (
    <AppContext.Provider value={{ siteConfig, isLoaded }}>
      {children}
    </AppContext.Provider>
  );
} */

function App() {
  return (
    <AppProvider>
      <AppContent />
    </AppProvider>
  );
}

function AppContent() {
  const { siteConfig, isLoaded } = useAppContext();

  if (siteConfig?.app_color_brand_primary) {
    document.documentElement.style.setProperty(
      "--brand-primary",
      siteConfig?.app_color_brand_primary
    );
    document.documentElement.style.setProperty(
      "--brand-secondary",
      siteConfig?.app_color_brand_primary
    );
  }

  document.documentElement.style.setProperty(
    "--brand-primary-contrast",
    siteConfig?.app_color_brand_primary_contrast || "#fff"
  );

  if (siteConfig?.app_color_brand_secondary) {
    document.documentElement.style.setProperty(
      "--brand-accent",
      siteConfig?.app_color_brand_secondary
    );
  }

  if (siteConfig?.app_hotjar_id) {
    const hotjarScript = document.createElement("script");
    hotjarScript.innerHTML = `
  (function (h, o, t, j, a, r) {
  h.hj =
  h.hj ||
  function () {
    (h.hj.q = h.hj.q || []).push(arguments);
  };
  h._hjSettings = { hjid: "${siteConfig?.app_hotjar_id}", hjsv: 6 };
  a = o.getElementsByTagName("head")[0];
  r = o.createElement("script");
  r.async = 1;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
  })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
  `;

    document.head.appendChild(hotjarScript);
  }

  return (
    <AppProvider >
      {isLoaded ? (
        <BrowserRouter history={history}>
          <Routes />
          <GlobalStyle />
        </BrowserRouter>
      ) : (
        <></>
      )}
    </AppProvider>
  );
}

export default App;
