import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { Form, Input, Select } from '@rocketseat/unform';
import * as Yup from 'yup';
import StepLayout from '../../../pages/_layouts/step';
import { FaArrowLeft, FaEye } from 'react-icons/fa';
import api from '../../../services/api';

const schema = Yup.object().shape({
  origemcontato: Yup.string()
    .required('Por favor, escolha uma opção válida.'),
});

const Step2 = props => { 
  const { userData, currentStep, onSetUserData, jumpToStep } = props;
  const [origemContato, setOrigemContato] = useState([]);
  const [origemContatoValue, setOrigemContatoValue] = useState(props.userData.origemcontato || '');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    buscaOrigemContato();
  }, [currentStep]);

  function handleSubmit(formData) {
    onSetUserData(formData);
    // Jump to the next step if everything is okay
    jumpToStep(currentStep + 1);
  }

  const onGoBackClick = () => {
    jumpToStep(currentStep - 1);
  };

  const onGoNextClick = () => {
    jumpToStep(currentStep + 1);
  };

  const buscaOrigemContato = async () => {
    try {
      setLoading(true);
      var options = [];
      const response = await api.get('/auth/busca-origem-contato');
   
      if (response.data.error !== 0) {
        throw new Error(response.data.message);
      }

      const origemContato = response.data.origemContato;
     
      if (origemContato.length > 0) {
        options = origemContato.map(item => ({
          id: item.id,
          title: item.descricao
        }));
        options.unshift({ id: '', title: 'Selecione uma opção' });
      } 
      else {
        jumpToStep(currentStep + 1);
      }  

      setOrigemContato(options);
      setLoading(false);

    } catch (error) {
      jumpToStep(currentStep + 1);
    }
  };

  function handleChange(event) {
    setOrigemContatoValue(event.target.value);
  }

  return (
    <StepLayout>
      <div>
        <span className="wizard-header">
          <button type="button" className="button-back" onClick={onGoBackClick}>
            <FaArrowLeft />
            Voltar
          </button>
        </span>
        <div className="step step3">
          <div className="row">
            <h2>Como você nos conheceu?</h2>
            <div className="subtitle"></div>
            <Form
              className="form signup-form"
              schema={schema}
              onSubmit={handleSubmit}
              initialData={{
                origemcontato: userData.origemcontato || null,
              }}
            >
              <div className="form-item-horizontal">
                <div className="form-item">
                  <Select
                    name="origemcontato"
                    placeholder="Selecione uma opção..."
                    options={origemContato}
                    onChange={handleChange}
                    value={origemContatoValue}
                    disabled={loading}
                  />
                </div>
              </div>

              <div className="wizard-footer ">
                <button type="submit" disabled={loading}>
                  {loading ? 'Carregando...' : ' Próximo'}
                </button>
              </div>

              <button
                type="button"
                className="button-next"
                onClick={onGoNextClick}
              >
                Pular esta etapa
              </button>
            </Form>
          </div>
        </div>
      </div>
    </StepLayout>
  );
};

Step2.propTypes = {
  userData: PropTypes.isRequired,
  jumpToStep: PropTypes.func,
  onSetUserData: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
};

Step2.defaultProps = {
  jumpToStep: () => {},
};

export default Step2;
