import React from 'react';

import Lottie from 'react-lottie';
import { useAppContext } from '../../../context/AppContext';
import { FaCheckCircle } from 'react-icons/fa';
import StepLayout from '../../../pages/_layouts/step';

const Step6 = () => {
  const {siteConfig} = useAppContext()
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('../../../assets/checked.json'),

    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <StepLayout>
      <div className="step step3">
        <div className="row">
          <div className="subtitle">
            <div className="span-success">
              {' '}
              <Lottie options={defaultOptions} height={400} width={400} />
            </div>
            <span className="span-success-title">
              Pronto! Seu cadastro foi recebido com sucesso.
            </span>
            <br />
            <span className="span-success-content">
              Agora é só aguardar que iremos analisar o seu cadastro e em breve
              enviaremos as informações em seu e-mail.
            </span>
          </div>
          <br />
          <a href={`${siteConfig?.app_url}`}>
            <button type="button" className="button-catalog">
              Visualizar catálogo
            </button>
          </a>
        </div>
      </div>
    </StepLayout>
  );
};

export default Step6;
