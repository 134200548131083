import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import AuthLayout from '../pages/_layouts/auth';

export default function RouteWrapper({
  component: Component,
  componentName: ComponentName,
  isPrivate,
  ...rest
}) {
  if (isPrivate) {
    return <Redirect to="/" />;
  }
  const Layout = AuthLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <>
          {ComponentName === 'SignUp' ? (
            <Component {...props} />
          ) : (
            <Layout>
              <Component {...props} />
            </Layout>
          )}
        </>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  componentName: PropTypes.string,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};