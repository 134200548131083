import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    Authorization: 'Bearer ' + cookies.get('token'),
    // common: [('X-Requested-With': 'XMLHttpRequest')],
  },
});

export default api;
