/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';
import StepLayout from '../../../pages/_layouts/step';
import { FaArrowLeft, FaEye } from 'react-icons/fa';

const schema = Yup.object().shape({
  fone1: Yup.string()
    .matches(/(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/, 'Número inválido!')
    .required('Por favor, insira um telefone.'),
  nomecontato: Yup.string().required('Por favor, insira um nome.'),
  fone2: Yup.string()
    .default(undefined)
    .matches(/^$|(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/, 'Número inválido!'),
  emailnfe: Yup.string(),
  representante: Yup.string().matches(/^[0-9]*$/, 'O número é inválido'),
});

const Step3 = props => {
  // const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState(false);
  const [phone, setPhone] = useState(maskPhone(props.userData.fone1));
  const [phone2, setPhone2] = useState(maskPhone(props.userData.fone2));

  const { userData, currentStep, onSetUserData, jumpToStep } = props;

  function handleSubmit(formData) {
    var regex = /[.,()-\s]/g;
    formData.fone1 = formData.fone1.replace(regex, '').trim();
    formData.fone2 = formData.fone2.replace(regex, '').trim();
    onSetUserData(formData);

    // Jump to the next step if everything is okay
    jumpToStep(currentStep + 1);
  }

  function maskPhone(phone){
    if(!phone){
      return '';
    }
    var formatted = phone.replace(/\D/g, '');
    formatted = formatted.replace(/^0/, '');
    if (formatted.length > 10) {
      formatted = formatted.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (formatted.length > 5) {
      formatted = formatted.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
    } else if (formatted.length > 2) {
      formatted = formatted.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else if (formatted.length === 0) {
      formatted = '';
    } else {
      formatted = formatted.replace(/^(\d*)/, '($1');
    }
    return formatted;
  }

  function handleChangePhone(event) {
    setPhone(maskPhone(event.target.value));
  }

  function handleChangePhone2(event) {
    setPhone2(maskPhone(event.target.value));
  }

  const onGoBackClick = () => {
    jumpToStep(currentStep - 1);
  };

  return (
    <StepLayout>
      <div>
        <span className="wizard-header">
          <button type="button" className="button-back" onClick={onGoBackClick}>
            <FaArrowLeft />
            Voltar
          </button>
        </span>
        <div className="step step3">
          <div className="row">
            <h2>Contato</h2>
            <div className="subtitle">
              <p>Digite suas informações de contato</p>
            </div>
            <Form
              className="form signup-form"
              schema={schema}
              onSubmit={handleSubmit}
              initialData={{
                nomecontato: userData.nomecontato,
                emailnfe: userData.emailnfe,
                fone1: userData.fone1,
                fone2: userData.fone2,
                representante: userData.representante,
              }}
            >
              <div className="form-item">
                <label>Contato*</label>
                <Input
                  placeholder="Seu nome completo"
                  name="nomecontato"
                  inputMode="text"
                />
              </div>
          
              <div className="form-item">
                <label>Telefone*</label>
                <Input
                  name="fone1"
                  type="tel"
                  placeholder="Telefone"
                  inputMode="tel"
                  value={phone}
                  onChange={handleChangePhone}
                />
              </div>

              <div className="form-item">
                <label>Celular</label>
                <Input
                  name="fone2"
                  type="tel"
                  placeholder="Celular ou WhatsApp"
                  inputMode="tel"
                  value={phone2}
                  onChange={handleChangePhone2}
                />
              </div>
        
              <div className="form-item">
                <label>Email XML</label>
                <Input
                  name="emailnfe"
                  type="email"
                  placeholder="E-mail para receber XML"
                  inputMode="email"
                />

                <small>
                  Deixe em branco caso queira receber no mesmo e-mail de acesso.
                </small>
              </div>

              <div className="form-item">
                <label>Código do representante</label>
                <Input
                  name="representante"
                  placeholder="Opcional"
                  inputMode="decimal"
                />
              </div>

              <div className="wizard-footer ">
                <button type="submit">Próximo</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </StepLayout>
  );
};

Step3.propTypes = {
  userData: PropTypes.isRequired,
  jumpToStep: PropTypes.func,
  onSetUserData: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
};

Step3.defaultProps = {
  jumpToStep: () => {},
};

export default Step3;
