import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Content, Root } from './styles';
import Header from '../../../components/Header';

export default function StepLayout({ children }) {
  return (
    <>
      <Wrapper>
        <Content>{children}</Content>
      </Wrapper>
    </>
  );
}

StepLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
