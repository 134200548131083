import styled from 'styled-components';

export const Container = styled.div`
  padding: 3rem 2rem;
`;

export const Content = styled.div`
  display: flex;

  justify-content: center;

  a {
    img {
      height: 25px;
    }
  }
`;
