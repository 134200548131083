import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/SignIn/index';
import SignUp from '../pages/SignUp/index';
import ForgotPassword from '../pages/ForgotPassword/index';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} componentName={"SignIn"}/>
      <Route path="/cadastro" component={SignUp} componentName={"SignUp"}/>
      <Route path="/definir-senha" component={ForgotPassword} componentName={"ForgotPassword"}/>
    </Switch>
  );
}
