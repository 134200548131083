// AppContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const storedSiteConfig = localStorage.getItem('siteConfig');
  const initialSiteConfig = storedSiteConfig ? JSON.parse(storedSiteConfig) : {};

  const [siteConfig, setSiteConfig] = useState(initialSiteConfig);
  const [isLoaded, setIsLoaded] = useState(!!storedSiteConfig);

  const fetchData = async () => {
    try {
      const apiUrl = `${process.env.REACT_APP_API_URL}/v1/site-config`;
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data) {
        setSiteConfig(data);
        localStorage.setItem('siteConfig', JSON.stringify(data));
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    if (storedSiteConfig) {
      setIsLoaded(true);
      fetchData();
    } else {
      fetchData();
    }

  }, [isLoaded, storedSiteConfig]);

  return (
    <AppContext.Provider value={{ siteConfig, isLoaded }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return useContext(AppContext);
};
