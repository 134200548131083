import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

require('dotenv').config();

window.recaptchaOptions = {
  useRecaptchaNet: true,
};

ReactDOM.render(<App />, document.getElementById('root'));
