import React from 'react';
import { useAppContext } from '../../context/AppContext';
import logo from '../../assets/nologo.svg';
import { Container, Content } from './styles';

export default function Header() {
  const { siteConfig } = useAppContext();

  return (
    <Container>
      <Content>
        <a target="_self" href={`${siteConfig?.app_url}`}>
          <img
            src={siteConfig?.app_logo || logo}
            alt={siteConfig?.app_name}
          />
        </a>
      </Content>
    </Container>
  );
}
